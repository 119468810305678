const dateConfig = {
    altInput: true,
    altFormat: "d.m.Y",
    dateFormat: "Y-m-d",
    minDate: "today"
}

window.addEventListener('load', function () {

    const pageMenu = document.querySelector('.navigation__menu');

   /* document.addEventListener('click', (elem) => {
        // console.log(elem.target.classList.contains('js-toggleMenu'));
        if(
            !pageMenu.contains(elem.target) &&
            !elem.target.classList.contains('js-toggleMenu')
        ) {
            // console.log('click outside of page menu');
            pageMenu.classList.contains('open') && pageMenu.classList.remove('open');
        }
    });

    document.querySelectorAll('.js-toggleMenu').forEach((elem) => {
        elem.addEventListener('click', () => {
            // console.log('toggle menu...');
            pageMenu.classList.toggle('open');
        })
    });
        */

    const bookingForm = document.getElementById('bookingRequestForm');
    if (bookingForm) {
        const fromDatePicker = document.getElementById('fromDate');
        const toDatePicker = document.getElementById('toDate');

        const defaultDateFrom = new Date();
        const defaultDateTo = getDateIn(defaultDateFrom, 1);

        if (fromDatePicker && toDatePicker) {
            flatpickr(fromDatePicker, {
                ...dateConfig,
                defaultDate: defaultDateFrom,
                onChange: fromDateChange
            });

            const toFlatpickr = flatpickr(toDatePicker, {
                ...dateConfig,
                defaultDate: defaultDateTo
            });

            function fromDateChange(selectedDates) {
                if (!selectedDates.length) return;
                toFlatpickr.setDate(getDateIn(selectedDates[0], 1), null, "d.m.Y");
                toFlatpickr.set('minDate', selectedDates[0]);
            }
        };

        bookingForm.addEventListener('submit', function (e) {
            e.preventDefault();
            bookingFormSubmit(bookingForm);
        });
    };

    const requestForm = document.querySelector('form[id^="hotelKachelot-Buchungsanfrage"]');
    if (requestForm) {
        const countDateInput = document.getElementById('hotelKachelot-Buchungsanfrage-45790-number-1');
        const fromDateInput = document.getElementById('hotelKachelot-Buchungsanfrage-45790-text-7');
        const adultsInput = document.getElementById('hotelKachelot-Buchungsanfrage-45790-number-2');
        const childrenInput = document.getElementById('hotelKachelot-Buchungsanfrage-45790-number-3');
        const params = getParams(location.search);

        if (fromDateInput && params.fromDate) fromDateInput.value = params.fromDate;
        if (adultsInput && params.adults) adultsInput.value = params.adults;
        if (childrenInput && params.children) childrenInput.value = params.children;
        if (countDateInput && params.fromDate && params.toDate) countDateInput.value = getDifferenceDays(params.fromDate, params.toDate) || 1;
    }
});

const getDifferenceDays = (date1, date2) => {
    date1 = new Date(date1);
    date2 = new Date(date2);
    if (!date1 || !date2) return null;
    const diff = Math.abs(date2.getTime() - date1.getTime());
    return Math.ceil(diff / (1000 * 3600 * 24));
}

const getDateIn = (date, count) => {
    const resultDate = new Date(date);
    resultDate.setDate(resultDate.getDate() + count);
    return resultDate;
}

const getParams = (searchString) => {
    if (!searchString) return null;
    const params = {};
    const hashes = searchString.slice(searchString.indexOf('?') + 1).split('&');
    hashes.forEach((hash) => {
        let [key, val] = hash.split('=');
        params[key] = decodeURIComponent(val);
    });
    return params;
};

function convertDate(date) {
    const parts = date.split('-');
    const day = parts[2];
    const month = parts[1];
    const year = parts[0];

    const formattedDate = `${day}.${month}.${year}`;
    return formattedDate;
}

function bookingFormSubmit(bookingForm) {
    const formActionUrl = bookingForm.getAttribute('action');
    const connector = (formActionUrl.indexOf('?') > 0) ? '&' : '?';
    const formData = {
        fromDate: convertDate(document.getElementById('fromDate').value),
        toDate: convertDate(document.getElementById('toDate').value),
        adults: document.getElementById('adults').value,
        children: document.getElementById('children').value
    }
    const attributes = 'arrival=' + formData.fromDate + '&departure=' + formData.toDate + '&adults=' + formData.adults + '&children=' + formData.children;

    window.location.href = formActionUrl + connector + attributes;
}


document.addEventListener('DOMContentLoaded', () => {

    const mainNavToggle = document.querySelector('.js-toggleMainNav');
    const subNavToggle = document.querySelector('.js-toggleSubNav');

    const navigation = document.querySelector('.navigation');
    const mainNav = document.querySelector('.main-navigation');
    const subNav = document.querySelector('.sub-navigation');

    const scrollToTop = document.querySelector('.backToTop');

    if(subNavToggle) {
        subNavToggle.addEventListener('click', () => {
            toggleNav(subNav)
            subNavToggle.classList.toggle('active');
        });
    }

    mainNavToggle.addEventListener('click', () => {
        toggleNav(mainNav);

        if(mainNavToggle.classList.contains('icon_menu')) {
            mainNavToggle.classList.remove('icon_menu');
            mainNavToggle.classList.add('icon_close');
        } else {
            mainNavToggle.classList.add('icon_menu');
            mainNavToggle.classList.remove('icon_close');
        }

    });

    const toggleNav = (nav) => {
        if(nav.style.maxHeight) {
            nav.style.maxHeight = null;
        } else {
            nav.style.maxHeight = `${nav.scrollHeight}px`
        }
    };

    const toggleScrollToTop = () => {
        if(window.scrollY >= 300) {
            scrollToTop.style.display = 'block';
            navigation.classList.add('scrolled');
        } else {
            scrollToTop.style.display = 'none';
            navigation.classList.remove('scrolled');
        }
    }

    toggleScrollToTop();
    window.addEventListener('scroll', toggleScrollToTop);

});